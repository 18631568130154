<template>
  <!-- 渠道终端 -->
  <div class="container">
    <a-page-header
      :title="title"
      :sub-title="subTitle"
      @back="() => $router.go(-1)"
    />
    <div class="main-content">
      <div class="main-content-header">
        <div class="item">
          <div class="title">终端人员管理</div>
        </div>
        <div class="item">
          <a-button
            v-permission="'add'"
            type="primary"
            @click="
              $jump({
                path: '/channelTerminalAdd',
                query: { businessId: businessId }
              })
            "
          >
            新增终端人员
          </a-button>
        </div>
      </div>
      <my-table
        :columns="columns"
        :data-source="dataSource"
        :pagination="false"
        :loading="loading"
      >
        <template slot="rate" slot-scope="record">
          {{ record.rate }}%
        </template>
        <template slot="qrcode" slot-scope="record">
          <a-button
            type="link"
            @click="onPreviewQRcode(record.wechatAppletsQcode)"
          >
            预览图片
          </a-button>
        </template>
        <template slot="action" slot-scope="record">
          <a-button type="link" @click="onChange(record)">
            编辑
          </a-button>
          <a-button type="link">
            <router-link
              v-permission="'info'"
              :to="{
                path: '/channelTerminalInfo',
                query: { id: record.id }
              }"
            >
              详情
            </router-link>
          </a-button>
          <a-button type="link" @click="onDel(record.id)">
            删除
          </a-button>
        </template>
      </my-table>
    </div>

    <a-modal
      title="分享二维码"
      :visible="modalQrcodeVisible"
      @cancel="onPreviewQRcodeClose"
      :footer="false"
    >
      <img class="qrcode" :src="qrcode" />
    </a-modal>

    <a-modal
      title="编辑"
      :visible="modalChangeVisible"
      @cancel="onChangeClose"
      @ok="onChangeSubmit"
    >
      <a-form-model
        ref="ruleForm"
        :model="form"
        :rules="rules"
        :label-col="{ span: 7 }"
        :wrapper-col="{ span: 16 }"
      >
        <a-form-model-item ref="realName" label="终端人员名称" prop="realName">
          <a-input
            v-model="form.realName"
            placeholder="请输入终端人员名称"
            @blur="
              () => {
                $refs.realName.onFieldBlur()
              }
            "
          />
        </a-form-model-item>
        <a-form-model-item ref="rate" label="二级分成比例" prop="rate">
          <a-input
            v-model="form.rate"
            placeholder="请输入二级分成比例"
            @blur="
              () => {
                $refs.rate.onFieldBlur()
              }
            "
          />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import NP from 'number-precision'
export default {
  data() {
    return {
      businessId: '',
      subTitle: '',
      dataSource: [],
      columns: [
        {
          title: '序号',
          width: 70,
          customRender: (text, record, index) => index + 1
        },
        {
          title: '终端人员名称',
          width: 200,
          dataIndex: 'realName'
        },
        {
          title: '二级分成比例',
          width: 150,
          scopedSlots: { customRender: 'rate' }
        },
        {
          title: '分享二维码',
          scopedSlots: { customRender: 'qrcode' }
        },
        {
          title: '操作',
          width: 250,
          fixed: 'right',
          scopedSlots: {
            customRender: 'action'
          }
        }
      ],
      loading: true,
      modalQrcodeVisible: false,
      qrcode: '',
      modalChangeVisible: false,
      form: {
        realName: '',
        rate: ''
      },
      rules: {
        realName: [
          { required: true, message: '请输入终端人员名称', trigger: 'blur' }
        ],
        rate: [
          { required: true, message: '请输入二级分成比例', trigger: 'blur' }
        ]
      }
    }
  },
  created() {
    this.title = this.$getPageTitle()
    const query = this.$route.query
    this.businessId = query.id
    this.subTitle = query.name
    this.getChannelTerminal()
  },
  methods: {
    getChannelTerminal() {
      // 获取渠道商列表
      const data = {
        id: this.businessId
      }
      this.$axios.getChannelTerminal(data).then((res) => {
        const l = res.data.data
        l.forEach((e) => {
          e.rate = NP.times(e.rate, 100)
        })
        this.dataSource = l
        this.loading = false
      })
    },
    onPreviewQRcode(e) {
      // 预览二维码
      this.qrcode = e
      this.modalQrcodeVisible = true
    },
    onPreviewQRcodeClose() {
      // 关闭预览二维码
      this.qrcode = ''
      this.modalQrcodeVisible = false
    },
    onChange(e) {
      this.form = {
        id: e.id,
        realName: e.realName,
        rate: e.rate
      }
      this.modalChangeVisible = true
    },
    onChangeClose() {
      // 取消编辑
      this.$refs.ruleForm.resetFields()
      this.modalChangeVisible = false
    },
    onChangeSubmit() {
      // 新增渠道商
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.addLoading = true
          const data = {
            id: this.form.id,
            realName: this.form.realName,
            rate: NP.divide(this.form.rate, 100)
          }
          this.$axios.changeChannelTerminal(data).then(() => {
            this.$message.success('操作成功')
            this.onChangeClose()
            this.getChannelTerminal()
          })
        }
      })
    },
    onDel(id) {
      this.$confirm({
        title: '确定删除吗？',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk: () => {
          const data = {
            id: id,
            configType: 2
          }
          this.$axios.delChannel(data).then(() => {
            this.$message.success('操作成功')
            this.getChannelTerminal()
          })
        }
      })
    }
  }
}
</script>

<style scoped>
.qrcode {
  display: block;
  margin: 0 auto;
}
</style>
