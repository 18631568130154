var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('a-page-header',{attrs:{"title":_vm.title,"sub-title":_vm.subTitle},on:{"back":function () { return _vm.$router.go(-1); }}}),_c('div',{staticClass:"main-content"},[_c('div',{staticClass:"main-content-header"},[_vm._m(0),_c('div',{staticClass:"item"},[_c('a-button',{directives:[{name:"permission",rawName:"v-permission",value:('add'),expression:"'add'"}],attrs:{"type":"primary"},on:{"click":function($event){return _vm.$jump({
              path: '/channelTerminalAdd',
              query: { businessId: _vm.businessId }
            })}}},[_vm._v(" 新增终端人员 ")])],1)]),_c('my-table',{attrs:{"columns":_vm.columns,"data-source":_vm.dataSource,"pagination":false,"loading":_vm.loading},scopedSlots:_vm._u([{key:"rate",fn:function(record){return [_vm._v(" "+_vm._s(record.rate)+"% ")]}},{key:"qrcode",fn:function(record){return [_c('a-button',{attrs:{"type":"link"},on:{"click":function($event){return _vm.onPreviewQRcode(record.wechatAppletsQcode)}}},[_vm._v(" 预览图片 ")])]}},{key:"action",fn:function(record){return [_c('a-button',{attrs:{"type":"link"},on:{"click":function($event){return _vm.onChange(record)}}},[_vm._v(" 编辑 ")]),_c('a-button',{attrs:{"type":"link"}},[_c('router-link',{directives:[{name:"permission",rawName:"v-permission",value:('info'),expression:"'info'"}],attrs:{"to":{
              path: '/channelTerminalInfo',
              query: { id: record.id }
            }}},[_vm._v(" 详情 ")])],1),_c('a-button',{attrs:{"type":"link"},on:{"click":function($event){return _vm.onDel(record.id)}}},[_vm._v(" 删除 ")])]}}])})],1),_c('a-modal',{attrs:{"title":"分享二维码","visible":_vm.modalQrcodeVisible,"footer":false},on:{"cancel":_vm.onPreviewQRcodeClose}},[_c('img',{staticClass:"qrcode",attrs:{"src":_vm.qrcode}})]),_c('a-modal',{attrs:{"title":"编辑","visible":_vm.modalChangeVisible},on:{"cancel":_vm.onChangeClose,"ok":_vm.onChangeSubmit}},[_c('a-form-model',{ref:"ruleForm",attrs:{"model":_vm.form,"rules":_vm.rules,"label-col":{ span: 7 },"wrapper-col":{ span: 16 }}},[_c('a-form-model-item',{ref:"realName",attrs:{"label":"终端人员名称","prop":"realName"}},[_c('a-input',{attrs:{"placeholder":"请输入终端人员名称"},on:{"blur":function () {
              _vm.$refs.realName.onFieldBlur()
            }},model:{value:(_vm.form.realName),callback:function ($$v) {_vm.$set(_vm.form, "realName", $$v)},expression:"form.realName"}})],1),_c('a-form-model-item',{ref:"rate",attrs:{"label":"二级分成比例","prop":"rate"}},[_c('a-input',{attrs:{"placeholder":"请输入二级分成比例"},on:{"blur":function () {
              _vm.$refs.rate.onFieldBlur()
            }},model:{value:(_vm.form.rate),callback:function ($$v) {_vm.$set(_vm.form, "rate", $$v)},expression:"form.rate"}})],1)],1)],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"item"},[_c('div',{staticClass:"title"},[_vm._v("终端人员管理")])])}]

export { render, staticRenderFns }